import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { map, mergeMap, Observable, tap } from 'rxjs';

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectRouteDataParam, // factory function to select a route data param
    selectUrl, // select the current url
    selectTitle, // select the title if available
} = getRouterSelectors();
export interface RouterState {
    url: string;
    params: any | null;
    data: any;
}

@Injectable({ providedIn: 'root' })
export class RouterStore extends ComponentStore<RouterState> {
    constructor(private store: Store) {
        super({
            url: '',
            params: null,
            data: null,
        });
    }

    readonly getUrl = this.select((state) => state.url);
    readonly getData = this.select((state) => state.data);
    currentRoute$: Observable<any> = this.store.select(selectCurrentRoute);
    readonly getParams = this.store.select(selectQueryParams);
    readonly getRouteParams = this.store.select(selectRouteParams);

    readonly watchRouterEventsEffect = this.effect((activatedRoute$: Observable<ActivatedRoute>) =>
        activatedRoute$.pipe(
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }

                return route;
            }),
            tap((route) => this.patchState({ params: route.snapshot.params })),
            mergeMap((route) => route.data),
            tap((data: any) => {
                this.patchState({ data });
            }),
        ),
    );

    public getParam(param: string) {
        return this.select((state) => state.params[param]);
    }
}
